export const VISUAL_PREFERENCE_MODE = 'VISUAL_PREFERENCE_MODE';
export const ACCOUNT_NOTIFICATIONS = 'ACCOUNT_NOTIFICATIONS';
export const UPGRADE_COHORT = 'UPGRADE_COHORT';
export const HIDE_SAVE_TO_HOME_SCREEN_POPUP = 'HIDE_SAVE_TO_HOME_SCREEN_POPUP';

/**
 * Sets the HIDE_SAVE_TO_HOME_SCREEN_POPUP key in local storage
 */
export const setHideSaveToHomeScreenPopup = value => {
    localStorage.setItem(HIDE_SAVE_TO_HOME_SCREEN_POPUP, value);
};

/**
 * Gets the HIDE_SAVE_TO_HOME_SCREEN_POPUP key from local storage
 */
export const getHideSaveToHomeScreenPopup = () => {
    return localStorage.getItem(HIDE_SAVE_TO_HOME_SCREEN_POPUP);
};

/**
 * Sets the VISUAL_PREFERENCE_MODE key in local storage
 */
export const setVisualPreferenceMode = mode => {
    localStorage.setItem(VISUAL_PREFERENCE_MODE, mode);
};

/**
 * Gets the VISUAL_PREFERENCE_MODE key from local storage
 * @returns {Mixed} Null if not available, value if set
 */
export const getVisualPreferenceMode = () => {
    const value = localStorage.getItem(VISUAL_PREFERENCE_MODE);
    return value || null;
};

/**
 * Sets the ACCOUNT_NOTIFICATIONS key in local storage as a JSON string
 */
export const setAccountNotifications = notifications => {
    localStorage.setItem(ACCOUNT_NOTIFICATIONS, JSON.stringify(notifications));
};

/**
 * Gets the ACCOUNT_NOTIFICATIONS key from local storage
 * @returns {Mixed} Null if not available, object parsed from JSON if set
 */
export const getAccountNotifications = () => {
    const value = localStorage.getItem(ACCOUNT_NOTIFICATIONS);
    return value ? JSON.parse(value) : null;
};

/**
 * Gets the UPGRADE_COHORT key from local storage
 * @returns {Mixed} Null if not available, object parsed from JSON if set
 */
export const getUpgradeCohort = () => {
    const value = localStorage.getItem(UPGRADE_COHORT);
    return value ? JSON.parse(value) : null;
};

/**
 * Sets the UPGRADE_COHORT key in local storage
 */
export const setUpgradeCohort = cohort => {
    localStorage.setItem(UPGRADE_COHORT, JSON.stringify(cohort));
};
